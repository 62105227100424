import React from "react";
import Header from "./Header";
import Navigation from "./Navigation";
import Wallet from "./Wallet";

const Withdraw = () => {
  return (
    <div className="bg-white text-black dark:text-white dark:bg-[#242526] transition-colors duration-1000 min-h-screen">
      <Header />
      <div className="flex justify-center w-full p-4 mt-2">
        <Navigation />
      </div>
      <div className="flex justify-center w-full">
        <div className="p-4 w-full md:w-3/4">
          <Wallet />
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
