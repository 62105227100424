import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Navigation = () => {
    const location = useLocation();

  return (
    <div className='flex items-center justify-start md:justify-center w-full overflow-x-auto'>
        <Link to={'/dashboard'}><p className={`${location.pathname == '/dashboard' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Dashboard</p></Link>
        <Link to={'/withdraw'}><p className={`${location.pathname == '/withdraw' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 ml-4 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Wallet</p></Link>
        <Link to={'/teams'}><p className={`${location.pathname == '/teams' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 ml-4 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Referals</p></Link>
        <Link to={'/tree'}><p className={`${location.pathname == '/tree' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 ml-4 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Tree</p></Link>
        <Link to={'/manage'}><p className={`${location.pathname == '/manage' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 ml-4 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Manage</p></Link>
        {/* <Link to={'/lord'}><p className={`${location.pathname == '/lord' ? "bg-blue-400 text-white" : "bg-gray-200 text-black"} w-24 text-center md:w-32 ml-4 shadow-md p-3 font-semibold text-sm rounded-sm  hover:bg-blue-400 hover:text-white`}>Admin</p></Link> */}
    </div>
  )
}

export default Navigation