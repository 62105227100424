import { useState, createContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Homepage from './components/Homepage';
import Dashboard from './components/Dashboard';
import Crowdloan from './artifacts/contracts/Crowdloan.sol/CrowdLoan.json';
import Fee from './artifacts/contracts/Fee.sol/Fee.json';
import { message } from 'antd';
import { ethers } from 'ethers';
import Teams from './components/Teams';
import Withdraw from './components/Withdraw';
import BinaryTree from './components/BinaryTree';
import PurchaseId from './components/PurchaseId';
import Admin from './components/Admin';

const AppState = createContext();

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

function App() {
  const {ethereum} = window;
  const navigate = useNavigate();
  const [theme, setTheme] = useState('light');
  const [change, setChange] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [ids, setIds] = useState([]);
  const [curId, setCurId] = useState(null);
  const [idChange, setIdChange] = useState(0);

  const contractAddress = "0x992455b322d0215d8fEc7F5B63477676704dC985";
  const feeAddress = "0x9eD0CAfD57caa56Ab3e7b4DD9820C8A2795B9e2A";
  const usdtAddress = "0x3cb9FfB87AC02e731A8A1aF17e9447d400C5f77A";

  let provider;
  let signer;

  async function getProvider() {
    if(ethereum == null) {
      provider = new ethers.JsonRpcProvider();
    } else {
      provider = new ethers.BrowserProvider(window.ethereum);
      signer = await provider.getSigner();
    }
  }
  getProvider();

  async function getContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(contractAddress, Crowdloan.abi, signer);
  }

  async function getFeeContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(feeAddress, Fee.abi, signer);
  }

  async function getUsdt() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(usdtAddress, erc20Abi, signer);
  }
  
  const staticContract = new ethers.Contract(contractAddress, Crowdloan.abi, provider);
  const staticFeeContract = new ethers.Contract(feeAddress, Fee.abi, provider);
  const staticUsdt = new ethers.Contract(usdtAddress, erc20Abi, provider);

  useEffect(() => {
    let _theme = localStorage.getItem('theme');
    if(_theme !== null) {
      setTheme(_theme);
    }

    async function getWallet() {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        setWalletAddress(accounts[0]);
      } catch (error) {
        message.error("Install Web3 Wallet");
      }
    }

    getWallet();
  },[])

  useEffect(() => {
    async function getData() {
      if(walletAddress !== "") {
        setIds([]);
        let _ids = await staticContract.getUserIds(walletAddress);
        for(let i=0; i<Number(_ids[1]); i++) {
          setIds((prev) => [...prev, Number(_ids[0][i])]);
        }
        if(idChange > 0) {
          setCurId(Number(_ids[1]) > 0 ? Number(_ids[0][Number(_ids[1])-1]) : null);
        } else {
          setCurId(Number(_ids[1]) > 0 ? Number(_ids[0][0]) : null);
        }
      }
    }
    getData();
  },[walletAddress, idChange])

  const toggle = () => {
    if(theme === 'dark') {
      setTheme('light');
      localStorage.setItem('theme', 'light');
      } else if(theme === 'light') {
        setTheme('dark');
        localStorage.setItem('theme', 'dark');
    }
  }

  const Login = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      setWalletAddress(accounts[0]);
      navigate('/dashboard');
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  }

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
      return (
        `${days.toString().padStart(2, '0')} Days, ` +
        `${hr.toString().padStart(2, '0')} : ` +
        `${min.toString().padStart(2, '0')} : ` +
        `${sec.toString().padStart(2, '0')}`
      );
  };

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  const convert = (val) => {
    return Number(ethers.formatUnits(val, "mwei"));
  }

  return (
    <AppState.Provider value={{formatTime, toggle, getFeeContract, feeAddress, staticFeeContract, idChange, setIdChange, getContract, ids, curId, setCurId, getUsdt, usdtAddress, theme, Login, walletAddress, navigate, change, setChange, staticContract, staticUsdt, convert, contractAddress}}>
    <body className={theme}>
      <div className="App">
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/teams' element={<Teams />} />
          <Route path='/withdraw' element={<Withdraw />} />
          <Route path='/tree' element={<BinaryTree />} />
          <Route path='/manage' element={<PurchaseId />} />
          {/* <Route path='/lord' element={<Admin />} /> */}
        </Routes>
      </div>
    </body>
    </AppState.Provider>
  );
}

export default App;
export {AppState};
