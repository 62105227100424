import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';
import { message } from 'antd';
import Header from './Header';
import Navigation from './Navigation';
import {db} from '../firebase'
import { doc, setDoc } from 'firebase/firestore';

const PurchaseId = () => {
  const useAppState = useContext(AppState);
  const [leg, setLeg] = useState(0);
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");

  const purchase = async () => {
    if(name.length == 0 && mobile.length == 0) {
      message.warning("all fields are required");
      return;
    }

    setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let usdt = await useAppState.getUsdt();

            let _user = await useAppState.staticContract.getUserIds(user);
            if(Number(_user[1]) == 0) {
              await setDoc(doc(db, "users", user.toLowerCase()), {
                name: name,
                mobile: mobile,
                referrer: id2,
                leg: leg,
                account: user.toLowerCase()
              })
            }

            let tx = await contract.register(id2, leg, user);
            await tx.wait();
            
            setUser("");
            setLeg(0);
            
            let _fetchuser = await useAppState.staticContract.getUserIds(user);
            if(Number(_user[1]) > 0) {
              setId(Number(_fetchuser[0][Number(_user[1])]));
            }

            message.success("Sucessfully Registered");
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason);
            console.log(error);
        }
        setLoading(false);
  }

  const activate = async () => {
    setLoading2(true);
    try {
        let contract = await useAppState.getContract();
        let usdt = await useAppState.getUsdt();
        
        let _allowance = await useAppState.staticUsdt.allowance(useAppState.walletAddress, useAppState.contractAddress)
        if(useAppState.convert(_allowance) < 25) {
            let _allow = await usdt.approve(useAppState.contractAddress, ethers.parseUnits("1000000", "mwei"));
            await _allow.wait();
        }

        let tx = await contract.activate(id);
        await tx.wait();

        message.success("Sucessfully Activated");
        useAppState.setChange(useAppState.change + 1);
    } catch (error) {
        message.error(error.reason);
        console.log(error);
    }
    setLoading2(false);
  }

  return (
    <div className="bg-white text-black dark:text-white dark:bg-[#242526] transition-colors duration-1000 min-h-screen">
      <Header />
      <div className="flex justify-center w-full p-4 mt-2">
        <Navigation />
      </div>
      <div className="flex justify-center w-full">
        <div className="p-4 w-full md:w-3/4">

          <div className='w-full pink_blur flex flex-col items-start relative bg-white dark:bg-[#8080821a] shadow-lg p-3 pb-4 rounded-lg border-t-2 border-blue-300'>
            <div className='flex justify-between w-full'>
              <div>
                <p className='text-lime-500 font-semibold'>Register ID</p>
                <input value={id2} onChange={(e) => setId2(e.target.value)} placeholder='Referrer ID' className='shadow-sm mt-3 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={user} onChange={(e) => setUser(e.target.value)} placeholder='Address' className='shadow-sm mt-2 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' className='shadow-sm mt-2 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Mobile No.' className='shadow-sm mt-2 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <span className='w-full mt-2 flex items-center'><span onClick={() => setLeg(0)} className={`${leg == 0 ? "bg-purple-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm`}>Left Leg</span> <span onClick={() => setLeg(1)} className={`${leg == 1 ? "bg-pink-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm ml-2`}>Right Leg</span></span>
                <button onClick={purchase} className='bg-lime-500 mt-4 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Register</p>}</button>
              </div>
            </div>
          </div>

          <div className='w-full mt-6 pink_blur flex flex-col items-start relative bg-white dark:bg-[#8080821a] shadow-lg p-3 pb-4 rounded-lg border-t-2 border-pink-300'>
            <div className='flex justify-between w-full'>
              <div>
                <p className='text-lime-500 font-semibold'>Activate ID</p>
                <input value={id} onChange={(e) => setId(e.target.value)} placeholder='Enter ID' className='shadow-sm mt-3 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <button onClick={activate} className='bg-red-500 mt-4 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading2 ? <TailSpin height={16} color='white' /> : <p>Activate ($25)</p>}</button>
              </div>
              <div>
                <img src="purchase.png" className='h-36 rotate-90 mr-4' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PurchaseId