import React, { useContext, useState, useEffect } from 'react'
import {message, Modal} from 'antd'
import { TailSpin } from 'react-loader-spinner';
import {AppState} from '../App'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers } from 'ethers';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const Register = () => {
    const useAppState = useContext(AppState);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [referrer, setReferrer] = useState("0");
    const [leg, setLeg] = useState(1);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");

    useEffect(() => {
        async function getData() {
            let user = await useAppState.staticContract.getUserIds(useAppState.walletAddress);
            if(Number(user[1]) > 0) {
                setModalOpen(false);
            } else {
                setModalOpen(true);
            }
        }
        getData();
    },[useAppState.walletAddress, useAppState.change]);

    useEffect(() => {
        let ref = searchParams.get("ref");
        let leg = searchParams.get("leg");
        if(leg !== null) {
            setLeg(leg);
        }
        if(ref !== null) {
          setReferrer(ref)
        } else {
          setReferrer("0")
        }
    }, []);

    let OkText = loading ? <TailSpin height={14} color='white' /> : <p>Register</p>;

    const register = async () => {
        if(name.length == 0 && mobile.length == 0) {
          message.warning("All Fields are required");
          return;
        }

        setLoading(true);
        try {
            let contract = await useAppState.getContract();

            let tx = await contract.register(referrer, leg, useAppState.walletAddress);
            await setDoc(doc(db, "users", useAppState.walletAddress.toLowerCase()), {
                name: name,
                mobile: mobile,
                referrer: referrer,
                leg: leg,
                account: useAppState.walletAddress.toLowerCase()
            })
            await tx.wait();

            navigate('/dashboard');
            message.success("Sucessfully Registered");
            useAppState.setIdChange(useAppState.idChange + 1);
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            message.error(error.reason);
            console.log(error);
        }
        setLoading(false);
    }

  return (
    <div>
        <Modal title="Register" open={isModalOpen} closable={false} onOk={register} okText={OkText} cancelButtonProps={{className:'hidden'}} >
            <div className='flex w-full flex-col justify-center items-center'>
                <p className='text-sm font-medium break-all'>Referrer Id: {referrer == "0" ? "default Refer" : referrer}</p>
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' className='shadow-sm mt-2 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <input value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder='Mobile No.' className='shadow-sm mt-2 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
                <p className={`font-medium ${leg == 0 ? "bg-purple-500" : "bg-pink-500"} py-1 rounded-xl mt-2 cursor-default px-4 text-white`}>{leg == 0 ? "Left Leg" : "Right Leg"}</p>
            </div>
        </Modal>
    </div>
  )
}

export default Register