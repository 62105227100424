import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App'
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';

const Wallet = () => {
    const useAppState = useContext(AppState);
    const [income, setIncome] = useState({
        totalWithdrawable: 0,
        totalClaimedLoan: 0,
        totalCashbackIncome: 0,
        totalPairIncome: 0,
        totalRoyaltyIncome: 0,
        totalRecoveryIncome: 0,
        totalReferIncome: 0
    })
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [to, setTo] = useState("");
    const [amount, setAmount] = useState("");
    const [amount2, setAmount2] = useState("");

    useEffect(() => {
        async function getData() {
            let income = await useAppState.staticContract.incomeInfo(useAppState.curId);
            setIncome({
                totalWithdrawable: useAppState.convert(income.totalWithdrawable),
                totalClaimedLoan: useAppState.convert(income.totalClaimedLoan),
                totalReferIncome: useAppState.convert(income.totalReferIncome),
                totalRecoveryIncome: useAppState.convert(income.totalRecoveryIncome),
                totalPairIncome: useAppState.convert(income.totalPairIncome),
                totalCashbackIncome: useAppState.convert(income.totalCashbackIncome),
                totalRoyaltyIncome: useAppState.convert(income.totalRoyaltyIncome),
            })
        }
        getData();
    },[useAppState.wallet, useAppState.change, useAppState.curId])

    const withdraw = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.withdraw(useAppState.curId, ethers.parseUnits(amount2.toString(), "mwei"));
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);

            message.success("Sucessfully Withdrawn");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const transfer = async () => {
        setLoading2(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.internalTransfer(useAppState.curId, to, ethers.parseUnits(amount, "mwei"));
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);

            message.success("Sucessfully Transferred");
            setAmount("");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading2(false);
    }

  return (
    <div className='w-full flex flex-col items-start relative bg-white dark:bg-[#8080821a] shadow-lg p-3 rounded-lg border-t-2 border-blue-300'>
        <h1 className='font-mono font-bold w-full text-center text-lime-500'>Wallet</h1>
        <div className='pink_blur p-2 shadow-md font-medium flex justify-between items-center mt-2 w-full rounded-sm'>
            <p>Total Withdrawable : </p>
            <p className='flex items-center'>${income.totalWithdrawable} <img src='usdt.png' className='h-4 ml-2' /></p>
        </div>

        <div className='p-2 font-medium flex flex-col items-center mt-1 w-full rounded-sm'>
            <input value={amount2} onChange={(e) => setAmount2(e.target.value)} placeholder='Enter Amount' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            <button onClick={withdraw} className='bg-lime-500 text-white w-full mt-3 px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={16} color='white' /> : <p>Withdraw {Number(amount2) > 0 ? `$${amount2}` : null}</p>}</button>
        </div>

        <div className='p-2 mt-4 w-full'>
            <p className='font-mono font-semibold'>P2P Transfer</p>
            <input value={to} onChange={(e) => setTo(e.target.value)} placeholder='Enter ID' className='mt-1 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder='Enter Amount' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            <button onClick={transfer} className='bg-pink-500 mt-2 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading2 ? <TailSpin height={16} color='white' /> : <p>Transfer</p>}</button>
        </div>
    </div>
  )
}

export default Wallet