import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppState } from '../App'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {CopyFilled} from '@ant-design/icons'
import { message } from 'antd';
import {useTimer} from 'react-timer-hook'
import { TailSpin } from 'react-loader-spinner';
import numeral from 'numeral';
import { ethers } from 'ethers';
import moment from 'moment';
import { Select, Modal } from 'antd';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';


function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const UserInfo = () => {
    const useAppState = useContext(AppState);
    const intervalRef = useRef();
    const intervalRef2 = useRef();
    const [user, setUser] = useState({
        start: 0,
        referrer: 0,
        nextEWI: 0,
        nextEWIAmount: 0,
        nextEWITotalAmount: 0,
        withdrawable: 0
    })
    const [time, setTime] = useState(0);
    const [time2, setTime2] = useState(0);
    const [leg, setLeg] = useState(1);
    const [showTimer, setShowTimer] = useState(false);
    const [loading, setLoading] = useState({
      "0": false,
      "1": false,
    })
    const [loading2, setLoading2] = useState(false);
    const [bal, setBal] = useState("0");
    const [bal2, setBal2] = useState("0");
    const [newId, setNewId] = useState({
      leg: 2,
      ref: ""
    })
    const [newIdLoad, setNewIdLoad] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [dbInfo, setDbInfo] = useState({
      name: "",
      mobile: ""
    })

    useEffect(() => {
        async function getData() {
            setShowTimer(false);
            let _bal = await useAppState.staticUsdt.balanceOf(useAppState.walletAddress);
            setBal(numeral(useAppState.convert(_bal)).format());
            
            let provider = new ethers.BrowserProvider(window.ethereum);
            let _bal2 = Number(ethers.formatEther(await provider.getBalance(useAppState.walletAddress)));
            setBal2(numeral(_bal2).format("0.00"));

            let _dbInfo = await getDoc(doc(db, "users", useAppState.walletAddress.toLowerCase()));
            setDbInfo({
              name: _dbInfo.data()['name'],
              mobile: _dbInfo.data()['mobile']
            })

            let _user = await useAppState.staticContract.userInfo(useAppState.curId);
            let _income = await useAppState.staticContract.incomeInfo(useAppState.curId);
            let _loan = await useAppState.staticContract.getNextEWI(useAppState.curId);
            setUser({
                start: Number(_user.start),
                referrer: Number(_user.referrer),
                nextEWI: Number(_loan[0]),
                nextEWIAmount: useAppState.convert(_loan[1]),
                nextEWITotalAmount: useAppState.convert(_loan[2]),
                withdrawable: useAppState.convert(_income.totalWithdrawable)
            })
            if(Number(_loan[0]) > 0) setShowTimer(true);
        }
        getData();
    },[useAppState.walletAddress, useAppState.curId, useAppState.change, useAppState.idChange])

    useEffect(() => {
        intervalRef.current = setInterval(() => {
          if(Number(user.start) > 0) {
            setTime(Date.now() - Number(user.start) * 1000);
          } else {
            setTime(0);
          }
        }, 10);
        return () => clearInterval(intervalRef.current);
      }, [user.start]);

    useEffect(() => {
        intervalRef2.current = setInterval(() => {
          if(Number(user.nextEWI) > 0) {
            setTime2(Date.now() - Number(user.nextEWI) * 1000);
          } else {
            setTime2(0);
          }
        }, 10);
        return () => clearInterval(intervalRef2.current);
      }, [user.nextEWI]);

      const payEMI = async (_type) => {
        setLoading({...loading, [_type]: true});
        try {
            let contract = await useAppState.getContract();
            if(_type == 0) {
              let tx = await contract.payEWI(useAppState.curId);
              await tx.wait();
            } else {
              let tx = await contract.payFullEWI(useAppState.curId);
              await tx.wait();
            }
            setShowTimer(false);
            useAppState.setChange(useAppState.change + 1);
            message.success("Sucessfully Paid");
          } catch (error) {
            message.error(error.reason);
            console.log(error);
          }
          setLoading({...loading, [_type]: false});
        }

        const activate = async () => {
          setLoading2(true);
          try {
              let contract = await useAppState.getContract();
              let usdt = await useAppState.getUsdt();
              
              let _allowance = await useAppState.staticUsdt.allowance(useAppState.walletAddress, useAppState.contractAddress)
              if(useAppState.convert(_allowance) < 25) {
                  let _allow = await usdt.approve(useAppState.contractAddress, ethers.parseUnits("1000000", "mwei"));
                  await _allow.wait();
              }
  
              let tx = await contract.activate(useAppState.curId);
              await tx.wait();

              message.success("Sucessfully Activated");
              useAppState.setChange(useAppState.change + 1);
              useAppState.setIdChange(useAppState.idChange + 1);
            } catch (error) {
              message.error(error.reason);
              console.log(error);
          }
          setLoading2(false);
        }
        
        const handleChange = (value) => {
        useAppState.setCurId(value);
      };

      const register = async () => {
        setNewIdLoad(true);
        try {
          let contract = await useAppState.getContract();
          
          let tx = await contract.register(newId.ref, newId.leg, useAppState.walletAddress);
          await tx.wait();
          
          message.success("Sucessfully Registered");
          useAppState.setChange(useAppState.change + 1);
          useAppState.setIdChange(useAppState.idChange + 1);
          setModalOpen(false);
        } catch (error) {
            message.error(error.reason);
            console.log(error);
        }
        setNewIdLoad(false);
    }

    let OkText = newIdLoad ? <TailSpin height={14} color='white' /> : <p>Register</p>;

  return (
    <div className='w-full transition-colors duration-1000 flex flex-col items-start bg-white dark:bg-[#8080821a] shadow-lg p-3 rounded-lg border-t-2 border-pink-300 red_blur'>
        <Modal title="Register" open={isModalOpen} closable={true} onCancel={() => setModalOpen(false)} onOk={register} okText={OkText} cancelButtonProps={{className:'hidden'}} >
            <div className='flex w-full flex-col justify-center items-center'>
              <input value={newId.ref} onChange={(e) => setNewId((prev) => ({...prev, ref: e.target.value}))} placeholder='Enter Referrer ID' className='mt-1 font-semibold w-full outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
              <span className='w-full mt-2 flex items-center'><span onClick={() => setNewId({...newId, leg: 0})} className={`${newId.leg == 0 ? "bg-purple-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm`}>Left Leg</span> <span onClick={() => setNewId({...newId, leg: 1})} className={`${newId.leg == 1 ? "bg-pink-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm ml-2`}>Right Leg</span></span>
            </div>
        </Modal>

        <img src='user.png' className='h-32' />
        <span className='font-mono bg-blue-500 rounded-sm text-white font-semibold text-sm py-3 pl-3 pr-1 whitespace-nowrap max-w-full overflow-x-auto'><span>{useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(38)}</span><span className='py-2 px-3 ml-2 rounded-sm bg-gray-200 text-black'>${bal} USDT</span> <span className='py-2 px-3 rounded-sm bg-gray-200 text-black'>{bal2} Matic</span></span>

        <div className='mt-3 flex items-center justify-start overflow-x-auto w-full whitespace-nowrap'>
        <span className='mr-2 flex justify-center items-center cursor-pointer py-1 px-3 rounded-sm font-semibold bg-cyan-500 shadow-sm text-white'>{useAppState.curId}</span>

          <Select
            defaultValue={"Select"}
            style={{
              width: 160,
            }}
            onChange={handleChange}
            options={useAppState.ids.map((e) => ({value: e, label: e}))}
          />

          <span onClick={() => setModalOpen(true)} className='flex justify-center items-center cursor-pointer hover:bg-gray-200 py-1 px-3 rounded-sm ml-3 font-bold bg-white shadow-sm text-black'>+</span>
        </div>
        
        {user.start > 0 ?
        <>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Name <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{dbInfo.name}</span></span>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Contract No. <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{dbInfo.mobile}</span></span>
          <span className='text-lg font-bold mt-2'>{useAppState.formatTime(time)}</span>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Referred By <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{user.referrer == 0 ? "Default Refer" : user.referrer}</span></span>
          {showTimer ? <span className='flex justify-center items-center text-sm font-medium mt-3'>Next EWI <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{(user.nextEWI * 1000) < moment().valueOf() ? useAppState.formatTime(time2) : <MyTimer expiryTimestamp={user.nextEWI * 1000} />}</span></span> : null}
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Wallet <span className='ml-3 bg-gray-200 rounded-sm px-3 text-gray-800'>${user.withdrawable}</span></span>
          {showTimer ? <span className='w-full mt-4 flex items-center'><span onClick={() => payEMI(0)} className={`bg-green-500 text-white py-2 px-3 rounded-md cursor-pointer font-semibold text-sm`}>{loading["0"] ? <TailSpin height={18} color='white' /> : <span>Pay Weekly (${user.nextEWIAmount})</span>}</span> <span onClick={() => payEMI(1)} className={`bg-red-500 text-white py-2 px-3 rounded-md cursor-pointer font-semibold text-sm ml-4`}>{loading["1"] ? <TailSpin height={18} color='white' /> : <span>Pay Full (${user.nextEWITotalAmount})</span> } </span></span> : null}
          <span className='w-full mt-4 flex items-center'><span onClick={() => setLeg(0)} className={`${leg == 0 ? "bg-purple-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm`}>Left Leg Link</span> <span onClick={() => setLeg(1)} className={`${leg == 1 ? "bg-pink-500 text-white" : "bg-gray-200 text-black"} py-2 px-3 rounded-md cursor-pointer font-semibold text-sm ml-4`}>Right Leg Link</span></span>
          <CopyToClipboard onCopy={() => message.success("Copied!")} text={`https://crowdloan.me/dashboard?ref=${useAppState.curId}&leg=${leg}`}>
              <span className='max-w-full cursor-pointer text-sm font-medium mt-3 overflow-x-auto bg-gray-200 rounded-sm px-3 py-2 text-gray-800 whitespace-nowrap'>{`https://crowdloan.me/dashboard?ref=${useAppState.curId}&leg=${leg}`} <CopyFilled className='ml-2 cursor-pointer' /></span>
          </CopyToClipboard>
        </>
        :
        <>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Name <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{dbInfo.name}</span></span>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Contract No. <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{dbInfo.mobile}</span></span>
          <span className='flex justify-center items-center text-sm font-medium mt-3'>Wallet <span className='ml-3 bg-gray-200 rounded-sm px-3 text-gray-800'>${user.withdrawable}</span></span>
          <span onClick={activate} className={`bg-green-500 text-white py-2 px-3 rounded-md cursor-pointer font-semibold text-sm mt-3`}>{loading2 ? <TailSpin height={18} color='white' /> : "Activate ID ($25)"}</span> 
        </>
        }
    </div>
  )
}

export default UserInfo