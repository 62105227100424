import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Navigation from "./Navigation";
import { AppState } from "../App";
import { DownOutlined, LeftOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { TailSpin } from "react-loader-spinner";

const BinaryTree = () => {
  const useAppState = useContext(AppState);
  const [address, setAddress] = useState("");
  const [B1, setB1] = useState("");
  const [B2, setB2] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState({
    address: "",
    directTeam: "",
    start: 0,
    left: 0,
    right: 0,
    referrer: ""
  })
  const zeroAddr = "0x0000000000000000000000000000000000000000";

  useEffect(() => {
    async function getData() {
      setAddress(useAppState.curId);
      let _binary1 = await useAppState.staticContract.getBinaryTeamUsers(
        useAppState.curId
      );
      setB1(Number(_binary1[0]));
      setB2(Number(_binary1[1]));
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change, useAppState.curId]);

  const fetchDetails = async (_addr) => {
    setLoading(true);
    try {
        if(_addr == address) {
            let _user = await useAppState.staticContract.userInfo(_addr);
            let upline = _user.upline;
            setAddress(Number(upline));
            let _binary1 = await useAppState.staticContract.getBinaryTeamUsers(upline);
            setB1(Number(_binary1[0]));
            setB2(Number(_binary1[1])); 
        } else {
            setAddress(_addr);
            let _binary1 = await useAppState.staticContract.getBinaryTeamUsers(_addr);
            setB1(Number(_binary1[0]));
            setB2(Number(_binary1[1]));
        }
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  };

  const findUser = async (_addr) => {
    setLoading2(true);
    try {
        setModalOpen(true)
        let _user = await useAppState.staticContract.userInfo(_addr);
        let _binary = await useAppState.staticContract.getBinaryTeam(_addr);
        setUser({
            address: _addr,
            directTeam: Number(_user.directTeam),
            start: Number(_user.start),
            left: Number(_binary[0]),
            right: Number(_binary[1]),
            referrer: Number(_user.referrer)
        })
    } catch (error) {
      message.error(error.reason);
    }
    setLoading2(false);
  };

  return (
    <div className="bg-white text-black dark:text-white dark:bg-[#242526] transition-colors duration-1000 min-h-screen">
      <Header />
      <div className="flex justify-center w-full p-4 mt-2">
        <Navigation />
      </div>
      {loading ? <div className="mt-14 w-full flex justify-center"><TailSpin height={32} /></div> : 
      <div className="flex flex-col items-center mt-2 w-full p-4 overflow-x-auto min-h-screen pink_blur">
        <Modal
          title="User Info"
          open={isModalOpen}
          closable={true}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{ className: "hidden" }}
          cancelButtonProps={{ className: "hidden" }}
        >
        { loading2 ? <TailSpin height={14} /> :
          <div className="flex w-full flex-col justify-center items-start">
            <img src="treeuser.png" className="h-20" />
            <p className="text-sm font-medium whitespace-nowrap">
              Id: <span className="text-gray-700">{user.address}</span>
            </p>
            <p className="text-sm font-medium whitespace-nowrap mt-2">
              Start: <span className="text-gray-700">{new Date(user.start * 1000).toLocaleString()}</span>
            </p>
            <p className="text-sm font-medium whitespace-nowrap mt-2">
              Left Team: <span className="text-gray-700">{user.left}</span>
            </p>
            <p className="text-sm font-medium whitespace-nowrap mt-2">
              Right Team: <span className="text-gray-700">{user.right}</span>
            </p>
            <p className="text-sm font-medium whitespace-nowrap mt-2">
              Referrer: <span className="text-gray-700">{user.referrer}</span>
            </p>
          </div>
        }
        </Modal>

        <h1 className="text-lime-500 font-bold text-2xl">Binary Tree</h1>
        <h1 className="text-teal-500 font-bold">
          ( {address} )
        </h1>

        <div className="flex mt-4 flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center cursor-pointer">
            <img onClick={() => findUser(address)} src="treeuser.png" className="h-14" />
            <h1 onClick={() => findUser(address)} className="text-teal-500 font-semibold text-xs">
              {address}
            </h1>
            {address !== useAppState.curId ? <span
                onClick={() => fetchDetails(address)}
                className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
            >
              Back <LeftOutlined />
            </span> : null}
          </div>
          <div className="border-2 mt-1 w-0 border-blue-500 h-16"></div>
        </div>

        <div className="flex relative justify-between w-2/3 md:w-1/2 border-t-0 border-blue-500 rounded-xl">
          <div className="flex flex-col justify-center items-center">
            <div className="border-2 w-0 border-blue-500 h-16"></div>
            <div className="flex flex-col items-center cursor-pointer">
              {B1 == 0 ? (
                <>
                  <img src="restrict.png" className="h-14" />
                  <h1 className="text-red-500 font-semibold text-xs">
                    (None)
                  </h1>
                  <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                    Vacant
                  </span>
                </>
              ) : (
                <>
                  <img onClick={() => findUser(B1)} src="treeuser.png" className="h-14" />
                  <h1 onClick={() => findUser(B1)} className="text-teal-500 font-semibold text-xs">
                    ({B1})
                  </h1>
                  <span
                    onClick={() => fetchDetails(B1)}
                    className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                  >
                    Tree <DownOutlined />
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="absolute top-0 right-[30px] left-[30px] border-t-4 border-blue-500"></div>

          <div className="flex flex-col justify-center items-center">
            <div className="border-2 w-0 border-blue-500 h-16"></div>
            <div className="flex flex-col items-center cursor-pointer">
              {B2 == 0 ? (
                <>
                  <img src="restrict.png" className="h-14" />
                  <h1 className="text-red-500 font-semibold text-xs">
                    (None)
                  </h1>
                  <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                    Vacant
                  </span>
                </>
              ) : (
                <>
                  <img onClick={() => findUser(B2)} src="treeuser.png" className="h-14" />
                  <h1 onClick={() => findUser(B2)} className="text-teal-500 font-semibold text-xs">
                    ({B2})
                  </h1>
                  <span
                    onClick={() => fetchDetails(B2)}
                    className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                  >
                    Tree <DownOutlined />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    }
    </div>
  );
};

export default BinaryTree;
