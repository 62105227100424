import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDUa3plkSokumVFWEIGMT-ItSkAniqGl8w",
  authDomain: "crowdloan-7357b.firebaseapp.com",
  projectId: "crowdloan-7357b",
  storageBucket: "crowdloan-7357b.appspot.com",
  messagingSenderId: "445150738785",
  appId: "1:445150738785:web:e01c907e6360923152ad20"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);