import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import { useTimer } from 'react-timer-hook';

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const Binary = () => {
    const useAppState = useContext(AppState);
    const pairIncome = 1.5;
    const [binary, setBinary] = useState({
        left: 0,
        right: 0,
        claimed: 0,
        unclaimed: 0,
        img: "binary_0.png",
        dayIncome: 0,
        time: 0,
        monthlyPairs: 0
    })
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isAvl, setAvl] = useState(false);

    useEffect(() => {
        async function getData() {
            setShow(false);
            let _user = await useAppState.staticContract.userInfo(useAppState.curId);
            let _binaryTeam = await useAppState.staticContract.getBinaryTeam(useAppState.curId);
            let _binaryPair = await useAppState.staticContract.getBinaryPairs(useAppState.curId);
            let _dayIncome = await useAppState.staticContract.getUserDayBinaryIncome(useAppState.curId);
            let _nextDay = await useAppState.staticContract.getNextDay();
            let _curMonth = await useAppState.staticContract.royaltyMonth();
            let _curMonthPairs = await useAppState.staticContract.monthlyPairs(useAppState.curId, _curMonth);
            let _isAvl = await useAppState.staticContract.isBinaryClaimAvl();
            setAvl(_isAvl);

            let _img = "binary_0.png";
            if(_binaryTeam[0] > 0 && _binaryTeam[1] > 0) {
                _img = "binary_2.png";
            } else if(_binaryTeam[0] > 0) {
                _img = "binary_12.png"
            } else if(_binaryTeam[1] > 0) {
                _img = "binary_11.png"
            }
            setBinary({
                left: Number(_binaryTeam[0]),
                right: Number(_binaryTeam[1]),
                claimed: Number(_user.binaryPairClaimed),
                unclaimed: Number(_binaryPair) - Number(_user.binaryPairClaimed),
                img: _img,
                dayIncome: useAppState.convert(_dayIncome),
                time: Number(_nextDay),
                monthlyPairs: Number(_curMonthPairs)
            })
            setShow(true);
        }   
        getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.curId])

    const claimPairs = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            let tx = await contract.claimBinaryIncome(useAppState.curId);
            await tx.wait();
            useAppState.setChange(useAppState.change + 1);
            message.success("Sucessfully Claimed");
        } catch (error) {
            message.error(error.reason)
        }
        setLoading(false);
    }

  return (
    <div className='w-full duration-1000 flex flex-col items-start  shadow-lg p-3 rounded-lg  blue_blur'>
        <div className='flex justify-between w-full'>
            <div>
                <p className='text-lg font-semibold font-mono text-lime-500'>Binary Team</p>
                <div className='mt-3'>
                    <p className='text-sm font-medium'>Left Leg : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >{binary.left}</span></p>
                    <p className='text-sm font-medium mt-2'>Right Leg : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >{binary.right}</span></p>
                    <p className='text-sm font-medium mt-2'>Claimed Pairs : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >{binary.claimed}</span></p>
                    <p className='text-sm font-medium mt-2'>Unclaimed Pairs : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >{binary.unclaimed}</span></p>
                    <p className='bg-gray-200 px-2 py-1 text-sm mt-3 pink_blur text-black text-center font-semibold rounded-sm'>Monthly Pairs : {binary.monthlyPairs}/25</p>
                    {binary.unclaimed > 0 && isAvl ? <button onClick={claimPairs} className='mt-3 bg-lime-500 text-white font-bold text-sm px-5 py-2 rounded-sm'>{loading ? <TailSpin height={16} color='white' /> : <p>Claim ${binary.unclaimed * pairIncome}</p>}</button> : null}
                </div>
            </div>
            <div>
                <img src={binary.img} className='h-40' />
                <p className='bg-gray-200 px-2 py-1 teal_blur text-black text-center font-bold rounded-sm'>${binary.dayIncome}/$105</p>
                {show ? <span className='flex justify-center items-center text-sm font-medium mt-3'>Next Day <span className='ml-3 bg-gray-200 rounded-xl px-3 text-gray-800'>{<MyTimer expiryTimestamp={binary.time * 1000} />}</span></span> : null}
            </div>
        </div>
    </div>
  )
}

export default Binary