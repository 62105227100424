import React, { useContext, useEffect } from 'react'
import { AppState } from '../App'
import Header from './Header';
import Register from './Register';
import UserInfo from './UserInfo';
import Loans from './Loans';
import Binary from './Binary';
import Revenue from './Revenue';
import Royalty from './Royalty';
import Navigation from './Navigation';
import PurchaseId from './PurchaseId'

const Dashboard = () => {
  return (
    <div className='bg-white pb-4 text-black dark:text-white dark:bg-[#242526] transition-colors duration-1000 min-h-screen'>
      <Header />
    
      <div className='flex justify-center w-full px-4 mt-6'>
        <Navigation />
      </div>

      <Register />
      
      <div className='flex justify-center px-4 md:p-0 mt-6'>
        <div className='md:w-3/4 w-full'>
          <UserInfo />
        </div>
      </div>

      <div className='mt-6 flex justify-center px-4 md:p-0'>
        <div className='md:w-3/4 w-full'>
          <Binary />
        </div>
      </div>

      <div className='mt-6 flex justify-center px-4 md:p-0'>
        <div className='md:w-3/4 w-full'>
          <Revenue />
        </div>
      </div>

      <div className='mt-6 flex justify-center px-4 md:p-0'>
        <div className='md:w-3/4 w-full'>
          <Royalty />
        </div>
      </div>

      <div className='mt-6 flex justify-center px-4 md:p-0'>
        <div className='w-full md:w-3/4'>
          <Loans />
        </div>
      </div>
    </div>  
  )
}

export default Dashboard