import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Navigation from "./Navigation";
import { AppState } from "../App";
import { useTimer } from "react-timer-hook";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const Teams = () => {
    const useAppState = useContext(AppState);
    const [users, setUsers] = useState([{
        sno: 0,
        address: 0,
        start: 0,
        ewi: 0,
        fullEwi: 0,
        nextEwi: 0,
        directTeam: 0,
        name: "",
        contact: ""
    }]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        async function getData() {
            setShow(false);
            setUsers([]);
            let _users = await useAppState.staticContract.getDirectTeam(useAppState.curId);
            for(let i=0; i<_users[1].length; i++) {
                let _user = await getDoc(doc(db, "users", (_users[0][i].account).toLowerCase()))
                setUsers((prev) => [...prev,
                    {
                        sno: i+1,
                        address: Number(_users[1][i]),
                        start: Number(_users[0][i].start),
                        ewi: useAppState.convert(_users[2][i]),
                        fullEwi: useAppState.convert(_users[3][i]),
                        nextEwi: Number(_users[0][i].nextEWITime),
                        directTeam: Number(_users[0][i].directTeam),
                        name: _user.data()['name'],
                        contact: _user.data()['mobile'],
                    }
                ])
            }
            setShow(true);
        }
        getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.curId])

  return (
    <div className="bg-white text-black dark:text-white dark:bg-[#242526] transition-colors duration-1000 min-h-screen">
      <Header />
      <div className="flex justify-center w-full p-4 mt-2">
        <Navigation />
      </div>
      <div className="mt-2 w-full p-4">
      <div class="overflow-x-scroll w-full teal_blur rounded-lg dark:text-white text-gray-800">
          <table class="w-full whitespace-nowrap">
            <thead class="bg-[#8080821a] font-semibold">
              <th class="text-left py-3 px-2">SNo.</th>
              <th class="text-left py-3 px-2">Id</th>
              <th class="text-left py-3 px-2">Name</th>
              <th class="text-left py-3 px-2">Contact</th>
              <th class="text-left py-3 px-2">Started</th>
              <th class="text-left py-3 px-2">EWI</th>
              <th class="text-left py-3 px-2">Time</th>
              <th class="text-left py-3 px-2">Direct Team</th>
            </thead>
            {users.map((e, i) => {
              return (
                <tr key={i} class="border-b border-gray-700 font-semibold">
                  <td class="py-3 px-2 font-bold">
                      <div className={`rounded-full bg-blue-500 dark:bg-pink-500 text-sm text-white flex justify-center items-center h-8 w-8 font-semibold`}>
                        {e.sno}
                      </div>
                  </td>

                  <td class="py-3 px-2">{e.address}</td>
                  <td class="py-3 px-2">{e.name}</td>
                  <td class="py-3 px-2">{e.contact}</td>
                  <td class="py-3 px-2">{e.start > 0 ? new Date(e.start * 1000).toLocaleString() : <span className="text-red-500">Unactivated</span>}</td>
                  <td class="py-3 px-2">{e.start > 0 ? <span>${e.ewi} <span className="text-xs font-medium text-purple-500">/${e.fullEwi}</span></span> : <span className="text-red-500">Unactivated</span>}</td>
                  <td class="py-3 px-2">{e.start > 0 ? (show ? <MyTimer expiryTimestamp={e.nextEwi * 1000} /> : null) : <span className="text-red-500">Unactivated</span>}</td>
                  <td class="py-3 px-2">{e.start > 0 ? e.directTeam : <span className="text-red-500">Unactivated</span>}</td>
                </tr>
              );
            })}
          </table>
      </div>
    </div>
    </div>
  );
};

export default Teams;
