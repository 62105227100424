import React, { useContext } from "react";
import {SunOutlined, MoonOutlined} from '@ant-design/icons' 
import { AppState } from "../App";
import { Link } from "react-router-dom";

const Header = () => {
    const useAppState = useContext(AppState);

  return (
    <header className="sticky top-0 w-full z-50">
      <nav className="bg-white border-gray-200 shadow-md py-2.5 dark:bg-[#18191a]">
        <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
          <Link to={'/'} className="flex items-center">
            <img
              src="logo192.png"
              className="h-6 mr-3 sm:h-9"
              alt="crowdloan Logo"
            />
            <span className="self-center text-xl font-bold whitespace-nowrap dark:text-white">
              CrowdLoan
            </span>
          </Link>
          <div className="flex items-center lg:order-2">
            <button
              onClick={() => useAppState.toggle()}
              className="text-black mr-4 px-3 py-1 bg-gray-100 rounded-lg hover:bg-white focus:ring-blue-300 ring-2"
            >
              {useAppState.theme == "light" ? (
                <MoonOutlined color="black" />
              ) : (
                <SunOutlined color="black" />
              )}
            </button>
            <button
              onClick={() => useAppState.navigate('/')}
              className="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-400"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
