import React, {useState, useEffect, useContext} from 'react'
import { AppState } from '../App';

const Revenue = () => {
  const useAppState = useContext(AppState);
    const [income, setIncome] = useState({
        totalIncome: 0,
        totalRoyaltyIncome: 0,
        totalCashbackIncome: 0,
        totalPairIncome: 0,
        totalRecoveryIncome: 0,
        totalReferIncome: 0
    })

    useEffect(() => {
        async function getData() {
            let income = await useAppState.staticContract.incomeInfo(useAppState.curId);
            setIncome({
                totalIncome: useAppState.convert(income.totalIncome),
                totalCashbackIncome: useAppState.convert(income.totalCashbackIncome),
                totalPairIncome: useAppState.convert(income.totalPairIncome),
                totalRecoveryIncome: useAppState.convert(income.totalRecoveryIncome),
                totalReferIncome: useAppState.convert(income.totalReferIncome),
                totalRoyaltyIncome: useAppState.convert(income.totalRoyaltyIncome),
            })
        }
        getData();
    },[useAppState.wallet, useAppState.change, useAppState.curId])

  return (
    <div className='w-full pink_blur flex flex-col items-start relative bg-white dark:bg-[#8080821a] shadow-lg p-3 pb-4 rounded-lg border-t-2 border-blue-300'>
      <div className='flex justify-between w-full'>
            <div>
                <p className='text-lg font-semibold font-mono text-lime-500'>Revenue</p>
                <div className='mt-3'>
                    <p className='font-medium'>Total Revenue : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalIncome}</span></p>
                    <p className='font-medium mt-2'>Total Referal : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalReferIncome}</span></p>
                    <p className='font-medium mt-2'>Total Binary  : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalPairIncome}</span></p>
                    <p className='font-medium mt-2'>Total EWI  : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalRecoveryIncome}</span></p>
                    <p className='font-medium mt-2'>Total Cashback  : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalCashbackIncome}</span></p>
                    <p className='font-medium mt-2'>Total Royalty  : <span className='ml-2 font-semibold bg-gray-200 rounded-xl px-3 text-gray-800' >${income.totalRoyaltyIncome}</span></p>
                </div>
            </div>
            <div>
                <img src="income.png" className='h-40' />
            </div>
        </div>
    </div>
  )
}

export default Revenue