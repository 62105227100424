import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App";
import { useTimer } from "react-timer-hook";
import { message } from "antd";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";

function MyTimer({ expiryTimestamp }) {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp });

  return (
    <p>
      <span>{days} Days, </span>
      <span>{hours.toString().padStart(2, "0")}</span>:
      <span>{minutes.toString().padStart(2, "0")}</span>:
      <span>{seconds.toString().padStart(2, "0")}</span>
    </p>
  );
}

const Royalty = () => {
  const useAppState = useContext(AppState);
  const [royalty, setRoyalty] = useState({
    fund: 0,
    distTime: 0,
    lastDist: 0,
  });
  const [royaltyUsers, setRoyaltyUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setShow(false);
      let _royal = await useAppState.staticContract.getRoyaltyDetails();
      let _royalDist = await useAppState.staticContract.royaltyLastDist();
      setRoyalty({
        fund: useAppState.convert(_royal[1]),
        distTime: Number(_royal[0]),
        lastDist: Number(_royalDist),
      });
      setRoyaltyUsers([]);
      for(let i=0; i<_royal[2].length; i++) {
        setRoyaltyUsers((prev) => [...prev, Number(_royal[2][i])]);
      }
      setShow(true);
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change, useAppState.curId]);

  const distRoyalty = async () => {
    setRoyalty(true);
    try {
      let contract = await useAppState.getContract();
      let tx = await contract.distributeRoyalty();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully distributed");
    } catch (error) {
      message.error(error.reason);
    }
    setRoyalty(false);
  }

  return (
    <div className="w-full duration-1000 flex flex-col items-start  shadow-lg p-3 rounded-lg  blue_blur">
      <div className="flex flex-col items-center w-full">
        <div className="flex flex-col items-center relative h-44 w-full rounded-xl md:w-[400px]">
          <p className="text-2xl font-bold text-blue-500">Royalty Pool</p>
          <p className="text-xs font-semibold mt-2">
            Last Distributed on{" "}
            {new Date(Number(royalty.lastDist) * 1000).toLocaleString()}
          </p>
          <p className="text-3xl text-pink-500 font-bold flex justify-center items-center mt-1">
            ${Number(royalty.fund).toFixed(2)}{" "}
          </p>
          <p className="text-xs mt-1 text-[#e67deb] font-semibold">
            Pool Time Remaining
          </p>
          {show ? (
            <p className="text-xl font-mono font-semibold px-4 mt-2 py-2 rounded-lg bg-gray-300 bg-opacity-25">
              <MyTimer expiryTimestamp={Number(royalty.distTime) * 1000} />
            </p>
          ) : null}
        </div>

        <div className="w-full">
          {royaltyUsers.length > 0 ? (
            <h1 className="font-semibold px-4 mt-6 md:px-8">
              Royalty <span className="text-[#f0c259]">Achievers</span>
            </h1>
          ) : null}
          {royaltyUsers.length > 0 ? (
            <div className="overflow-auto h-52 md:h-36 px-0 md:px-8 flex justify-between w-full mt-2">
              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-gray-700 dark:text-gray-400 font-medium text-sm">
                  Sno.
                </p>
                {royaltyUsers.map((e, i) => {
                  return (
                    <p key={i} className="whitespace-nowrap  p-1">
                      {i + 1}
                    </p>
                  );
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-gray-700 dark:text-gray-400 font-medium text-sm">
                  Id
                </p>
                {royaltyUsers.map((e, i) => {
                  return (
                    <p key={i} className="whitespace-nowrap  p-1">
                      {e}
                    </p>
                  );
                })}
              </div>

              <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="whitespace-nowrap p-1 text-gray-700 dark:text-gray-400 font-medium text-sm">
                  Reward
                </p>
                {royaltyUsers.map((e, i) => {
                  return (
                    <p
                      key={i}
                      className="whitespace-nowrap flex justify-center items-center p-1"
                    >
                      {Number(royalty.fund / royaltyUsers.length).toFixed(2)}{" "}
                      <img src="usdt.png" className="h-4 ml-2" />
                    </p>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>

        { moment().valueOf() > (royalty.distTime * 1000) ? <button onClick={distRoyalty} className="bg-green-500 px-4 text-sm font-semibold rounded-sm py-2 text-white">{loading ? <TailSpin height={14} color="white" /> : "Distribute"}</button> : null}
      </div>
    </div>
  );
};

export default Royalty;
