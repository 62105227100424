import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { message } from "antd";

const Loans = () => {
    const useAppState = useContext(AppState);
    const Amount = [8, 15, 30, 90, 200, 500, 1000, 5000, 10000, 25000, 50000];
    const cashback = [2, 3, 6, 10, 50, 100, 200, 1000, 2000, 5000, 10000];
    const weeks = [3, 4, 6, 8, 10, 10, 10, 10, 10, 10, 10];
    const emi = [2, 3, 4, 10, 15, 40, 80, 400, 800, 2000, 4000];

    const [loans, setLoans] = useState([{
        sno: 0,
        amount: 0,
        cashback: 0,
        weeks: 0,
        emi: 0,
        emiPaid: 0,
        available: false,
        isClaimed: false
    }]);
    const [loanLoading, setLoanLoading] = useState(false);
    const [loading, setLoading] = useState({
      "0": false,
      "1": false
    });

    useEffect(() => {
        async function getData() {
          setLoanLoading(true);
            let _loan = await useAppState.staticContract.getLoanDetails(useAppState.curId);
            setLoans([]);
            for(let i=0; i<11; i++) {
              let loanObj = {
                  sno: i+1,
                  amount: Amount[i],
                  cashback: cashback[i],
                  weeks: weeks[i],
                  emi: emi[i],
                  emiPaid: useAppState.convert(_loan[2][i]),
                  available: _loan[0][i],
                  isClaimed: _loan[1][i],
              }
              setLoans((prev) => [...prev, loanObj]);
            }
            
          setLoanLoading(false);
        }
        getData();
    },[useAppState.walletAddress, useAppState.change, useAppState.curId])

    const claimLoan = async (_loan, _type) => {
      setLoading({...loading, [_type]: true});
      try {
        let contract = await useAppState.getContract();
        let tx = await contract.claimLoan(_loan, _type, useAppState.curId);
        await tx.wait();
        useAppState.setChange(useAppState.change + 1);
        message.success("Sucessfully Claimed");
      } catch (error) {
        message.error(error.reason);
      }
      setLoading({...loading, [_type]: false});
    }

  return (
    <div className="mt-2 w-full">
      <div class="overflow-x-scroll w-full teal_blur rounded-lg dark:text-white text-gray-800">
          <table class="w-full whitespace-nowrap">
            <thead class="bg-[#8080821a]">
              <th class="text-left py-3 px-2">SNo.</th>
              <th class="text-left py-3 px-2">Loan</th>
              <th class="text-left py-3 px-2">Available</th>
              <th class="text-left py-3 px-2">EWI</th>
              <th class="text-left py-3 px-2">Weeks</th>
              <th class="text-left py-3 px-2">Cashback</th>
              <th class="text-left py-3 px-2">EWI Paid</th>
            </thead>
            {loans.map((e, i) => {
              return (
                <tr key={i} class="border-b border-gray-700 font-bold">
                  <td class="py-3 px-2 font-bold">
                      <div className={`rounded-full bg-blue-500 dark:bg-pink-500 text-sm text-white flex justify-center items-center h-8 w-8 font-semibold`}>
                        {e.sno}
                      </div>
                  </td>

                  <td class="py-3 px-2">${e.amount}</td>

                  <td class="py-3 px-2 text-sm font-medium">
                    {loanLoading ? <TailSpin height={14} /> : 
                    (e.isClaimed ? <p className="text-lime-500">Claimed</p> :  (e.available ?
                    <div className="flex items-center">
                      <p onClick={() => claimLoan(i, 0)} className="bg-lime-500 text-white px-2 py-1 rounded-sm max-w-14  min-h-6 flex justify-center items-center cursor-pointer">{loading["0"] ? <ThreeDots height={6} color="white" /> : "Claim" }</p> 
                      <p onClick={() => claimLoan(i, 1)} className="ml-3 bg-red-500 text-white px-2 py-1 rounded-sm min-h-6 flex justify-center items-center  cursor-pointer">{loading["1"] ? <ThreeDots height={6} color="white" /> : "Get Instant Cashback" }</p>
                    </div> : <span className="bg-red-500 text-white px-2 rounded-sm">N/A</span>))}
                  </td>

                  <td class="py-3 px-2">${e.emi}</td>
                  <td class="py-3 px-2">{e.weeks} <span className="text-xs font-medium text-purple-500">Weeks</span></td>
                  <td class="py-3 px-2">${e.cashback}</td>
                  <td class="py-3 px-2 text-lime-500">{loanLoading ? <TailSpin height={14} /> : <span>${e.emiPaid}</span>}</td>
                </tr>
              );
            })}
          </table>
      </div>
    </div>
  );
};

export default Loans;
